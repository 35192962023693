/**
 * Matrix/studieaanbod
 **/

 .matrix-full{
    margin-bottom: 50px;
 }

/**
 * Tabs
**/
.pills-nav{
    .nav-item{
        margin-right: 15px;
        margin-bottom: 15px;

        &:last-of-type{
            display: none;
        }
    }

    .btn{
        border: 2px solid $white;
        padding-top: 15px;
        padding-bottom: 15px;

        &.active{
            background-color: transparent;
            border: 2px solid $black;
        }
    }
}

.finaliteiten-wrapper{
    margin-top: 100px;
    border-bottom-right-radius: 50px;

    h3{
        position: relative;
        top: -50px;
    }
}

.richting{
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 20px;

    &:last-child{
        padding-bottom: 50px;
        border-bottom: 0;
    }

    a{
        text-decoration: none;
    }

    h4{
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        color: $grey;    
    }

    .domein{
        @extend .label;
    }
}

/**
 * Single
**/
@include media-breakpoint-up(lg){
    .label-container{
        margin-left: 75px;
        margin-bottom: 15px;

        .label{
            margin-right: 15px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}