/**
 * Body
**/

.row:before, .row:after {
	display: none !important;
}

.container-fluid{
	max-width: 2000px;

	@include media-breakpoint-up(lg){
		padding-right: 120px;
	}
}

.site-wrapper{
	display: flex;
}

.site-content{
	flex-grow: 1;
	// Belangrijk voor slick slider om te werken (https://github.com/kenwheeler/slick/issues/1195)
	width: calc(100% - 120px); // -120px = left sticky breedte
}

img{
	max-width: 100%;
}

.intro-text{
	color: $grey;
	margin-bottom: 65px;
}