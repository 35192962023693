/**
 * Typography
 **/

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

body{
    font-family: $primary-font;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-weight: 700;    
}

h1,
h2{
    font-size: 40px;
    line-height: 46px;
}

h3{
    font-size: 26px;    
    line-height: 32px;
}

p,
ul,
ol{
    font-size: 18px;
    line-height: 32px;
}

p a:not(.btn){
    color: $blue;
    text-decoration: none;
    display: inline-block;
    padding-bottom:1.5px;
    background-image: linear-gradient($blue 0 0);
    background-position: right bottom; /* OR left bottom*/
    background-size: 100% 1.5px;
    background-repeat: no-repeat;
    transition: all 0.3s ease;    

    &:hover{
        background-size: 0% 2px; 
        color: $yellow;      
    }
}

strong{
    color: $blue;
}

.label{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    border: 1.5px solid $black;
    border-radius: 50px;
    color: $black;
    display: inline-block;
    padding: 3px 35px;    
    
    &--blue{
        background-color: $blue;
        border-color: $blue;
    }
}


/**
 * Buttons
**/

.btn{
    border-radius: 50px;
    color: $black;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    line-height: 14px;    
    position: relative;
    transition: all .3s ease;

    padding: {
        top: 18px;
        bottom: 18px;
        left: 30px;
        right: 90px;
    }

    text-transform: uppercase;

    &:after{
        content:'';
        position: absolute;
        background-image: url('images/arrow-right.svg');
        width: 34px;
        height: 12px;
        right: 25px;
        top: 21px;
    }

    &:hover{
        color: $black;
        padding-right: 120px;
    }

    &--blue{
        border: 2px solid $blue;      
        border-radius: 50px;  
        background-color: $blue;

        &:hover{
            background-color: transparent;
        }
    }

    &--white{
        border: 2px solid $white;      
        border-radius: 50px;          
        background-color: $white;

        &:hover{
            background-color: transparent;
        }        
    }

    &--no-arrow{
        padding-right: 30px;

        &:after{
            display: none;
        }
    }
}
