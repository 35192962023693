/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(151, 210, 212, 1.0)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border: none;
	position: relative;
	top: 40px;
}


#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}

	@include media-breakpoint-up(lg){
		.dropdown{
			.dropdown-menu{
				display: inherit;
				opacity: 0;
				visibility: hidden;
				height: 0;
				width: 320px;
				box-shadow: 1px 1px 40px #00000012;

				.nav-link{
					transition: all 1.2s cubic-bezier(.24,1,.3,1) !important;
					transform: translateY(25px);
				}
			}

			&:hover{
				.dropdown-menu{
					opacity: 1;
					visibility: visible;
					height: auto;
					
					.nav-link{
						transform: translateY(0);
					}
				}
			}
		}
	}

	.dropdown-menu{
		border: none;
		//z-index: 9999;
		border-radius: 5px;
		border-bottom-right-radius: 20px;
		padding: 25px;

		.nav-link{
			margin-left: 0;
		}
	}	
}

/**
 * Logo
**/
.logo-small{
	@include media-breakpoint-up(lg){
		display: none;
	}
}

.logo-large{
	display:  none;

	@include media-breakpoint-up(lg){
		display: inherit;
	}
}

/**
 * Main navigation
**/
#nav-main{
	.navbar-brand{
		img{
			margin-top: 23px;
			margin-bottom: 21px;
			max-width: 202px;		
		}
	}

	.nav{
		margin: 0 0 0 auto;		
		padding-right: 120px;
	}

	.nav-link{
		color: $black;
		font-size: 16px;	
		margin-left: 30px;
		text-transform: lowercase;
		transition: color .2s ease;

		&:hover{
			color: $blue;
		}
	}
}