/**
  * Sticky sidebar
**/
.left-sticky{
    display: none;    

    @include media-breakpoint-up(lg){
        display: inherit;
        flex: 0 0 120px;
    }

    .logo-icon{
        background-image: url('images/mandela.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 70px;
        width: 70px;
        margin-right: 25px;
        margin-left: 25px;
        margin-top: 35px;
        margin-bottom: 35px;
        position: fixed;
    }

    .social-media{
        position: fixed;
        bottom: 50px;
        left: 45px;
        
        a{
            display: block;
            margin-bottom: 15px;
        }
    }
}

/** 
 * Header
**/
.header-image{
    background-image: url('images/header-home.png');
    background-size: cover;
    height: 150px;
    
    @include media-breakpoint-up(md){
        height: 250px;
    }

    @include media-breakpoint-up(lg){
        height: 400px;
    }
}