#footer{
    border-top-left-radius: 150px;
    padding-top: 75px;
    padding-bottom: 60px;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
        background-color: $yellow;
        z-index: -1;
    }

    h5{
        margin-bottom: 35px;
        font-size: 18px;
        margin-top: 17px;   
        
        @include media-breakpoint-down(md){
            margin-bottom: 15px;
            margin-top: 20px;            
        }
    }

    p{
        font-size: 14px;
        line-height: 22px;
        max-width: 240px;
    }
    
    ul{
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;

        li,
        a{
            font-size: 16px;
        }

        li strong{
            color: $black;
        }
    }

    a{
        color: $black;
        text-decoration: none;
        transition: all .2s ease;

        &:hover{
            color: $white;
        }
    }    

    .footer__contact,
    .footer__hours{
        p,
        ul,
        li{
            font-size: 16px;
        }
    }

    .footer__contact{
        ul{
            &:first-of-type{
                position: relative;
                margin-bottom: 40px;

                &:after{
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 1.5px;
                    background-color: $black;
                    bottom: -20px;
                }
            }
        }
    }
}

.footer-logo{
    margin-left: -12px;
}

.footer-go-logo{
    margin-top: 75px;

    @include media-breakpoint-down(md){
        margin-top: 25px;
        margin-bottom: 35px;        
    }
}

/**
 * Copyright footer
**/

.footer-final{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;

    p a,
    p{
        color: $black;
        font-size: 12px;
    }

    p a{
        &:hover{
            color: $blue;
        }

        background-image: none;
    }

    img{
        margin-top: 5px;
    }
}