/**
 * Utils
**/

/**
 * Backgrounds
 **/
 @each $name, $color in (
    'blue': $blue,
    'yellow': $yellow,
    'red': $red,
    'purple': $purple,
    'white': $white,      
    'black': $black,            
    ) {
	.bg--#{$name} {
		background-color: $color;
  	}
}

.bg--full{
    margin-left: -120px;
    padding-left: 120px;
}

/**
 * Blocks
**/
@each $name, $size in ('10': 10, '20': 20, '35': 35, '50': 50, '75': 75, '100': 100, '120': 120, '150': 150) {
	$mobile-size: calc($size/2);
    
    .block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}

    @include media-breakpoint-down(md){
        .block-#{$name} {
            padding-top: #{$mobile-size}px;
            padding-bottom: #{$mobile-size}px;
          }        
    }
}

/**
 * Classes
 **/
 .text-center{
    text-align: center !important;
 }