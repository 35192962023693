/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Fonts
 **/

$primary-font: 'Manrope', sans-serif;

/**
 * Colors
 **/

$blue: #97D2D4;
$yellow: #EEE6A4;
$red: #DA4E30;
$purple: #D1BCDC;
$white: #FFFFFF;
$black: #000000;
$grey: #696C6A;