/**
 * Front
**/

.elevator-pitch{
    position: relative;

    /* Background */
    &:after{
        content: '';
        position: absolute;
        background-image: url('images/background-mandela.svg');
        height: 365px;
        width: 180px;
        right: 0;
        top: 40px;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .btn{
        margin-top: 35px;
    }
}


/**
 * Matrix carousel studieaanbod
**/
.matrix{
    padding-bottom: 160px;

    h2{
        margin-bottom: 35px;
    }
}

.matrix-carousel{
    padding-left: 0;
    padding-right: 0;    

    /**
    * Slick slider
    **/

    .slick-slide{
        width: 100%;
    }

    .slick-next,
    .slick-prev{
        padding: 5px 15px;
        background-color: $white;    
        border-radius: 50px;
        width: 90px;
        height: 50px;
        border: 2px solid $white;
        transition: all .3s ease;

        display: none !important;

        &:hover{
            border: 2px solid $black;
        }

        &:before{
            content:'';
            width: 32px;
            height: 12px;
            position: absolute;
            top: 18px;
            left: 27px;
        }     
        
        @include media-breakpoint-up(lg){
            display: inherit !important;
        }
    } 
    
    .slick-prev{
        left: 0;
        bottom: -120px;
        top: auto;

        &:before{
            background-image: url('images/prev-arrow.svg');
        }
    }    

    .slick-next{
        top: auto;
        left: 105px;
        bottom: -120px;

        &:before{
            background-image: url('images/next-arrow.svg');
            width: 34px;
        }
    }
}

.matrix-item{
    margin-right: 25px;

    &:hover{
        .overlay{
            background-color: transparent;
        }
    }

    a{
        color: $black;
        text-decoration: none;
    }

    &__image-wrap{
        border-top-left-radius: 50px;
        overflow: hidden;
        position: relative;

        img{
            width: 100%;
        }
        
        .overlay{
            background-color: rgba($blue, .5);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all .4s ease;
        }
    }

    &__title-wrap{
        background-color: $white;
        padding: 15px 25px;
    }

    h3{
        font-size: 18px;     
    }
}

.matrix-link{
    position: relative;

    a{
        position: absolute;
        right: auto;
        left: 0;
        top: 43px;

        @include media-breakpoint-up(lg){
            right: 25px;            
            left: auto;
        }
    }
}

/**
 * Troeven
**/
.usps{
    padding-bottom: 0px !important;
}

/**
 * Blogs/In de kijker
**/

.blog{
    a{
        text-decoration: none;
    }

    &__image-wrapper{
        position: relative;

        img{
            width: 100%;
        }

        .category{
            position: absolute;
            top: 20px;
            right: 20px;
            border-radius: 25px;
            color: #000;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            padding: 2px 15px;
        }        
    }

    &__title{
        color: $black;
        font-size: 18px;
        margin-top: 20px;
    }

    &__description{
        color: $grey;
        padding-right: 75px;
    }
}

.more-blogs{
    margin-top: 65px;
}