/**
 * Single
**/

/**
  * Gutenberg pages
**/

.article-content{
    h1{
        margin-bottom: 45px;

        @include media-breakpoint-up(lg){
            margin-left: 75px;
            margin-right: 150px;
        }
    }
}

.article-body{
    @include media-breakpoint-up(lg){
        p,
        ul,
        ol,
        h2,
        h3,
        h4,
        h5,
        h6,
        .wp-block-table,
        .wp-block-buttons{
            margin-left: 75px;        
            margin-right: 150px;
        }
    }

    p,
    ul,
    .wp-block-buttons{
        margin-bottom: 30px;
    }

    h2,
    h3,
    h4,
    h5{
        margin-bottom: 25px;
    }

    img{
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    a{
        color: $black;
        text-decoration: none;
    }

    p a:not(.btn){
        color: $blue;

        &:hover{
            color: $yellow;            
        }
    }

    cite{
        color: $blue;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        margin-top: 50px;
        margin-bottom: 50px;
        display: block;
        padding-left: 30px;   
        position: relative;
        
        &:before{
            content:'';
            position: absolute;
            left: 0;
            height: 100%;
            width: 4px;
            background-color: $blue;
        }

        @include media-breakpoint-up(lg){
            font-size: 36px;
        }
    }

    ul{
        li{
            list-style-type: none;
            position: relative;

            &:before{
                content: '';
                position: absolute;
                left: -25px;
                top: 14px;
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background-color: $red;
            }
        }
    }

    table{
        margin-bottom: 30px;
    }

    table,
    .wp-block-table td, 
    .wp-block-table th{
        border: 0;
    }

    tr,
    th,
    td{
        border: 0;
        border-bottom: 1px solid rgba($blue, .35);
        padding-left: 20px;
    }

    .wp-block-table thead{
        background-color: $blue;
        border-bottom: 2px solid $blue;
    }    
}